var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "context-title",
        {
          attrs: {
            title: _vm.title,
            passedActions: _vm.primaryTitleBarActions,
          },
        },
        [
          _c("template", { slot: "secondary" }, [
            _c(
              "div",
              { staticClass: "d-flex", staticStyle: { "margin-left": "auto" } },
              [
                _vm.canEdit
                  ? _c(
                      "text-button",
                      {
                        staticClass: "block-xs--sm-left",
                        staticStyle: { "margin-right": "20px" },
                        attrs: { type: "add" },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.showProductSelection.apply(
                              null,
                              arguments
                            )
                          },
                        },
                      },
                      [_vm._v("Add Menu Item")]
                    )
                  : _vm._e(),
                _vm.canEdit
                  ? _c(
                      "text-button",
                      {
                        staticClass: "block-xs--sm-left",
                        staticStyle: { "margin-right": "20px" },
                        attrs: { type: "delete" },
                        nativeOn: {
                          click: function ($event) {
                            return (() =>
                              (_vm.deleteConfirmationModal = true)).apply(
                              null,
                              arguments
                            )
                          },
                        },
                      },
                      [_vm._v("Delete Image")]
                    )
                  : _vm._e(),
                _vm.hasPreviousItem()
                  ? _c(
                      "ActionButton",
                      {
                        attrs: {
                          type: "faded",
                          noIcon: true,
                          action: _vm.gotoPreviousItem,
                        },
                      },
                      [_vm._v("View Previous")]
                    )
                  : _vm._e(),
                _c(
                  "ActionButton",
                  { attrs: { type: "faded", noIcon: true, action: _vm.leave } },
                  [_vm._v("Close")]
                ),
                _vm.hasNextItem()
                  ? _c(
                      "ActionButton",
                      {
                        attrs: {
                          type: "faded",
                          noIcon: true,
                          action: _vm.gotoNextItem,
                        },
                      },
                      [_vm._v("View Next")]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c(
        "v-container",
        { staticClass: "list-card fill-height pa-3", attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { sm: "12" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-card",
                        { staticClass: "image-card elevation-0 ml-4" },
                        [
                          _c("v-img", {
                            attrs: {
                              src: _vm.imageURL,
                              "aspect-ratio": _vm.imageInfo.aspectRatio,
                              height: _vm.imageInfo.suggestedHeight * 2.5,
                              width: _vm.imageInfo.suggestedWidth * 2.5,
                              contain: "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "placeholder",
                                fn: function () {
                                  return [
                                    _c(
                                      "v-row",
                                      {
                                        staticClass: "fill-height ma-0",
                                        attrs: {
                                          align: "center",
                                          justify: "center",
                                        },
                                      },
                                      [
                                        _c("v-progress-circular", {
                                          attrs: {
                                            indeterminate: "",
                                            color: "#93BD20",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-row", { staticClass: "ml-0 mt-3 mb-3" }, [
                    _c("div", { staticClass: "row__icon" }),
                    _c("div", { staticClass: "row__title" }, [
                      _vm._v("Menu items associated with this image:"),
                    ]),
                  ]),
                  _vm.productSelections.length
                    ? _c(
                        "v-list",
                        { staticClass: "pt-0", attrs: { "hide-actions": "" } },
                        _vm._l(_vm.productSelections, function (item) {
                          return _c(
                            "v-list-item",
                            { key: item.id, staticClass: "tile" },
                            [
                              _c(
                                "v-row",
                                { staticClass: "mb-0 mt-0" },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { md: "5" } },
                                    [
                                      _c("v-list-item-title", [
                                        _vm._v(_vm._s(item.inventoryItemName)),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { md: "5" } },
                                    [
                                      _c("v-list-item-title", [
                                        _vm._v(_vm._s(item.inventoryItemID)),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _c("v-spacer"),
                                  _c(
                                    "v-col",
                                    { attrs: { md: "2" } },
                                    [
                                      _vm.canEdit
                                        ? _c("v-list-item-title", [
                                            _c("div", {
                                              staticClass: "delete-logo",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.removeAssociatedItem(
                                                    item
                                                  )
                                                },
                                              },
                                            }),
                                          ])
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }),
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.navigationConfirmationModal
        ? _c(
            "EditingModal",
            { attrs: { size: "sm" } },
            [
              _c(
                "AlertDialog",
                {
                  attrs: {
                    title: "Unsaved Changes",
                    onExit: () => (_vm.navigationConfirmationModal = false),
                    actions: _vm.navigationConfirmationActions,
                  },
                },
                [
                  _vm._v(
                    "You have unsaved changes, would you like to save these changes?"
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.deleteConfirmationModal
        ? _c(
            "EditingModal",
            { attrs: { size: "sm" } },
            [
              _c(
                "AlertDialog",
                {
                  attrs: {
                    title: "Confirm Delete",
                    onExit: () => (_vm.deleteConfirmationModal = false),
                    actions: _vm.deleteConfirmationActions,
                  },
                },
                [
                  _vm._v(
                    "Are you sure you want to remove this image from this product?"
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.productSelectionModal
        ? _c(
            "EditingModal",
            { staticClass: "modal" },
            [
              _c("ProductSelectionCard", {
                attrs: {
                  title: "Select items to attach to image",
                  preSelectedItems: _vm.modalItems,
                  includeGroups: true,
                },
                on: {
                  close: () => {
                    this.productSelectionModal = false
                  },
                  save: _vm.handleNewSelections,
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }