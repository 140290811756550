const imageServer = process.env.VUE_APP_IMAGE_SERVER;
import missing1x1 from 'img/image_add_1x1.svg'
import missing16x9 from 'img/image_add_16x9.svg'
import missing384x216 from 'img/image_add_384x216.svg'
import missing9x2 from 'img/image_add_9x2.svg'
import missing9x16 from 'img/image_add_9x16.svg'

let imageInfo = {
    "featured_9_2": {
        aspectRatio: 9.0 / 2.0,
        display: "Kiosk Feature",
        imagesPageDisplay: "Feature Item 9:2 (Kiosk)",
        suggestedHeight: 80,
        suggestedWidth: 360,
        missingImage: missing9x2,
        platformString: "Kiosk",
        formString: "Feature Item (9:2)",
        maxDimensions: {
            height: 240,
            width: 1080
        },
        typeID: 9,
    },
    "img_asset_384x216": {
        aspectRatio: 384.0 / 216.0,
        display: "Product / Original",
        imagesPageDisplay: "Original design 261x212 (Kiosk)",
        suggestedHeight: 80,
        suggestedWidth: 142,
        missingImage: missing384x216,
        platformString: "Kiosk",
        formString: "Original (261 x 212 px)",
        maxDimensions: {
            height: 212,
            width: 261
        },
        typeID: 0
    },
    "launch_1_1": {
        aspectRatio: 1,
        display: "OAT Launch",
        imagesPageDisplay: "Launch screen 1:1 (OAT)",
        suggestedWidth: 80,
        suggestedHeight: 80,
        missingImage: missing1x1,
        platformString: "OAT",
        formString: "Launch Screen (1:1)",
        maxDimensions: {
            height: 672,
            width: 672
        },
        typeID: 11,
    },
    "launch_9_16": {
        aspectRatio: 9.0 / 16.0,
        display: "Kiosk Launch",
        imagesPageDisplay: "Launch screen 9:16 (Kiosk)",
        suggestedHeight: 120,
        suggestedWidth: 67,
        missingImage: missing9x16,
        platformString: "Kiosk",
        formString: "Launch Screen (9:16)",
        maxDimensions: {
            height: 1920,
            width: 1080
        },
        typeID: 10,


    },
    "product_16_9": {
        aspectRatio: 16.0 / 9.0,
        display: "Product 16:9",
        imagesPageDisplay: "Product 16:9 (OAT)",
        suggestedWidth: 142,
        suggestedHeight: 80,
        missingImage: missing16x9,
        platformString: "OAT",
        formString: "Product (16:9)",
        maxDimensions: {
            height: 585,
            width: 1035
        },
        typeID: 8,
    },
    "product_1_1": {
        aspectRatio: 1.0,
        display: "Mobile/Kiosk/Web/OAT Product",
        suggestedWidth: 80,
        suggestedHeight: 80,
        imagesPageDisplay: "Product 1:1 (OAT, Mobile, Web, Kiosk)",
        missingImage: missing1x1,
        platformString: "Mobile, OAT, Web, Kiosk",
        formString: "Product (1:1)",
        maxDimensions: {
            height: 519,
            width: 519
        },
        typeID: 7
    },
    "store_image": {
        aspectRatio: 104.0 / 144.0,
        display: "",
        suggestedWidth: 144.0,
        suggestedHeight: 104.0,
        imagesPageDisplay: "",
        missingImage: missing1x1,
        platformString: "",
        formString: "",
        maxDimensions: {
            height: 312,
            width: 432
        },
        typeID: 99
    }
}

export const imageUploadInfo = () => {
    return imageInfo
}

export const transformImageV2ToArray = (imageV2) => {
    return Object.entries(imageV2)
        .map(([key, val]) => {
            return {
                imageType: key,
                imageUrl: fullUrlForImageValue(val),
                imageAspectRatio: aspectRatioForImageKey(key),
                display: displayStringForImageKey(key)
            };
        })
        .filter(el => el.imageUrl != null && el.imageUrl.length > 0);
}

export const fullUrlForImageName = (name) => {
    return `${imageServer}${name}`

}

export const fullUrlForImageValue = (partial) => {
    if (partial == null || partial.length == 0) return partial
    let trimmed = partial.charAt(0) == "/" ? partial.substring(1, partial.length) : partial
    return `${imageServer}${trimmed}`
}

export const aspectRatioForImageKey = (key) => {
    return imageInfo[key] == undefined ? 1 : imageInfo[key].aspectRatio
}

export const displayStringForImageKey = (key) => {
    return imageInfo[key] == undefined ? "" : imageInfo[key].display
}

export const imagesPageDisplayStringForImageKey = (key) => {
    return imageInfo[key] == undefined ? "" : imageInfo[key].imagesPageDisplay
}

export const dimensionsForImageKey = (key) => {
    if (imageInfo[key] == undefined) {
        return {
            width: 0,
            height: 0,
        }
    }
    return {
        width: imageInfo[key].suggestedWidth,
        height: imageInfo[key].suggestedHeight,

    }
}

export const missingImageForImageKey = (key) => {
    return imageInfo[key] == undefined ? "" : imageInfo[key].missingImage
}

export const imagePartialURLToRouteParam = (partialURL) => {
    return encodeURIComponent(partialURL)
}

export const generateImageFileName = (inventoryItemID, imageTypeKey, originalFileName) => {
    let extension = originalFileName.substr(originalFileName.lastIndexOf('.') + 1);
    let fileName = `${inventoryItemID}-${imageTypeKey}-${new Date().valueOf()}.${extension}`
    return fileName
}
