import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import { Permissions, Access, AccessAreas } from "helpers/access";

const IsPermissionedAsGrabber = (user) => {
  
    return user.email != undefined && (user.email.includes('@getgrab.com') || user.email.includes('@servy.us'))
}

const HasPermissions = (user, storeWaypointID) => {
    return user.storeAccess != undefined
}

const GetPermission = (user, storeWaypointID, key) => {
    if (IsPermissionedAsGrabber(user)) return Access.EDIT
    if (!HasPermissions(user)) return false

    let storeAccess = user.storeAccess.find(el => el.storeWaypointID == storeWaypointID)
    if (storeAccess == undefined) return false
    let permission = storeAccess.permissions[key]
    if (permission == undefined) return false

    return permission
}

const userCanView = (user, storeWaypointID, key) => {
    let permission = GetPermission(user, storeWaypointID, key)

    return permission == Access.VIEW || permission == Access.EDIT

}

const userCanEdit = (user, storeWaypointID, key) => {
    let permission = GetPermission(user, storeWaypointID, key)

    return permission == Access.EDIT

}

export default {
    computed: {
        canEdit() {
            let storeWaypointID = this.$route.params.store;
            return userCanEdit(
                this.user,
                storeWaypointID,
                this.permissionName,
            );
        },
        canView() {
            let storeWaypointID = this.$route.params.store;
            return userCanView(
                this.user,
                storeWaypointID,
                this.permissionName,
            );
        },
        permissionName() {
            if (this.$route.name == 'store-products' || this.$route.name == 'store-product-edit' || this.$route.name == 'store-product-add-new') {
                return AccessAreas.MENU_MODIFICATION
            }
            if (this.$route.name == 'store-settings-table-numbers' || this.$route.name.includes('store-settings-sections')) {
                return AccessAreas.TABLE_NUMBERS
            }
            if (this.$route.name == 'store-images' || this.$route.name == 'image-details')
                return AccessAreas.IMAGES
      
            if (this.$route.path.includes('upsell') || this.$route.path.includes('addon'))
                return AccessAreas.UPSELL
            return undefined
        },
        ...mapGetters(['user'])
    },
    methods: {
        userHasEditPermission(key) {
            return userCanEdit(this.user, this.$route.params.store, AccessAreas[key])
        },
        userHasViewPermission(key) {
            return userCanView(this.user, this.$route.params.store, AccessAreas[key])

        }
    },
}