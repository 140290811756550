<template>
  <div>
    <context-title :title="title" :passedActions="primaryTitleBarActions">
      <template slot="secondary">
        <div class="d-flex" style="margin-left: auto;">
          <text-button
            v-if="canEdit"
            class="block-xs--sm-left"
            style="margin-right: 20px;"
            type="add"
            @click.native="showProductSelection"
          >Add Menu Item</text-button>
          <text-button
            v-if="canEdit"
            class="block-xs--sm-left"
            style="margin-right: 20px;"
            type="delete"
            @click.native="() => deleteConfirmationModal = true"
          >Delete Image</text-button>
          <ActionButton
            v-if="hasPreviousItem()"
            type="faded"
            :noIcon="true"
            :action="gotoPreviousItem"
          >View Previous</ActionButton>
          <ActionButton type="faded" :noIcon="true" :action="leave">Close</ActionButton>

          <ActionButton
            v-if="hasNextItem()"
            type="faded"
            :noIcon="true"
            :action="gotoNextItem"
          >View Next</ActionButton>
        </div>
      </template>
    </context-title>
    <v-container fluid class="list-card fill-height pa-3">
      <v-row>
        <v-col sm="12">
          <v-row >
            <v-card class="image-card elevation-0 ml-4">
              <v-img
                :src="imageURL"
                :aspect-ratio="imageInfo.aspectRatio"
                :height="imageInfo.suggestedHeight*2.5"
                :width="imageInfo.suggestedWidth*2.5"
                contain
              >
                <template v-slot:placeholder>
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular indeterminate color="#93BD20"></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-card>
          </v-row>
          <v-row class="ml-0 mt-3 mb-3" >
            <div class="row__icon"></div>
            <div class="row__title">Menu items associated with this image:</div>
            <!-- </v-flex> -->
          </v-row>
          <v-list v-if="productSelections.length" hide-actions class="pt-0">
            <v-list-item v-for="item in productSelections" :key="item.id" class="tile">
              <v-row class="mb-0 mt-0" >
                <v-col md="5">
                  <v-list-item-title>{{ item.inventoryItemName }}</v-list-item-title>
                </v-col>
                <v-col md="5">
                  <v-list-item-title>{{ item.inventoryItemID }}</v-list-item-title>
                </v-col>
                <v-spacer />
                <v-col md="2">
                  <v-list-item-title v-if="canEdit">
                    <div @click="removeAssociatedItem(item)" class="delete-logo" />
                  </v-list-item-title>
                </v-col>
              </v-row>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </v-container>
    <EditingModal v-if="navigationConfirmationModal" size="sm">
      <AlertDialog
        title="Unsaved Changes"
        :onExit="() => navigationConfirmationModal = false"
        :actions="navigationConfirmationActions"
      >You have unsaved changes, would you like to save these changes?</AlertDialog>
    </EditingModal>
    <EditingModal v-if="deleteConfirmationModal" size="sm">
      <AlertDialog
        title="Confirm Delete"
        :onExit="() => deleteConfirmationModal = false"
        :actions="deleteConfirmationActions"
      >Are you sure you want to remove this image from this product?</AlertDialog>
    </EditingModal>
    <EditingModal v-if="productSelectionModal" class="modal">
      <ProductSelectionCard
        title="Select items to attach to image"
        :preSelectedItems="modalItems"
        :includeGroups="true"
        @close="() =>  {this.productSelectionModal = false}"
        @save="handleNewSelections"
      />
    </EditingModal>
  </div>
</template>

<script>
import ContextTitle from "components/context_title.vue";
import ActionButton from "components/action_button";
import TextButton from "components/text_button.vue";
import EditingModal from "components/editing_modal.vue";
import AlertDialog from "components/alert_dialog.vue";
import { fullUrlForImageValue, imageUploadInfo } from "helpers/images.js";
import ProductSelectionCard from "components/upsell/upsell_selection_card.vue";
import clone from "clone";
import AccessMixin from "mixins/access_helper.js";

import { mapGetters, mapActions } from "vuex";

export default {
    name: "ImageDetails",
    mixins: [AccessMixin],
    data() {
        return {
            initialAssociatedProducts: [],
            productSelectionModal: false,
            productSelections: [],
            modalItems: [],
            deleteConfirmationModal: false,
            deleteConfirmationActions: [
                {
                    type: "green",
                    display: "No",
                    run: () => (this.deleteConfirmationModal = false)
                },
                {
                    type: "remove-red",
                    display: "Yes",
                    run: this.deleteItem
                }
            ],
            pendingRoute: undefined,
            navigationConfirmationModal: false,
            navigationConfirmationActions: [
                {
                    type: "remove-red",
                    display: "No",
                    run: this.confirmNavigation
                },
                {
                    type: "proceed-green",
                    display: "Yes",
                    run: () => {
                        this.save().then(() => {
                            this.confirmNavigation();
                        });
                    }
                }
            ],
            leaveRoute: "store-images"
        };
    },
    props: {},
    components: {
        ContextTitle,
        ActionButton,
        TextButton,
        AlertDialog,
        EditingModal,
        ProductSelectionCard
    },
    mounted() {
        this.init();
    },
    computed: {
        title() {
            if (this.imageProduct == undefined) return "";
            return `${this.imageProduct.inventoryItemName} - ${this.imageInfo.display}`;
        },
        primaryTitleBarActions() {
            if (!this.dirty || !this.valid) return [];

            const items = [];

            const previous = {
                type: "proceed-green",
                display: "Save & View Previous",
                run: () => {
                    this.save().then(() => this.gotoPreviousItem());
                }
            };
            const saveClose = {
                type: "proceed-green",
                display: "Save & Close",
                run: () => {
                    this.save().then(() => this.leave());
                }
            };

            const saveNext = {
                type: "next-green",
                display: "Save & View Next",
                run: () => {
                    this.save().then(() => {
                        this.gotoNextItem();
                    });
                }
            };

            items.push(saveClose);

            if (this.hasNextItem(this.productId)) {
                items.push(saveNext);
            }

            return items;
        },

        dirty() {
            return (
                JSON.stringify(this.initialAssociatedProducts.map(el => el.id)) !=
        JSON.stringify(this.productSelections.map(el => el.id))
            );
        },
        imageInfo() {
            return imageUploadInfo()[this.$route.params.imageTypeKey];
        },
        imageURL() {
            if (this.imageProduct == undefined) return "";
            return fullUrlForImageValue(this.imageName);
        },
        imageName() {
            if (this.imageProduct == undefined) return "";
            if (this.$route.params.imageTypeKey == "img_asset_384x216") {
                return this.imageProduct.inventoryItemImageName;
            }
            return this.imageProduct.imageV2[this.$route.params.imageTypeKey];
        },
        imageProduct() {
            return this.productCategories
                .flatMap(el => el.children)
                .find(el => el.inventoryItemID == this.$route.params.inventoryItemID);
        },
        imageCategory() {
            for (let category of this.productCategories) {
                for (let product of category.children) {
                    if (product.inventoryItemID == this.$route.params.inventoryItemID) {
                        return category;
                    }
                }
            }

            return undefined;
        },
        allImageArray() {
            if (this.imageCategory == undefined) return [];

            let imageTypes = [
                "product_1_1",
                "product_16_9",
                "featured_9_2",
                "launch_1_1",
                "launch_9_16",
                "img_asset_384x216"
            ];

            return imageTypes.flatMap(type => {
                return this.imageCategory.children
                    .filter(el => {
                        if (type == "img_asset_384x216")
                            return el.inventoryItemImageName != "";
                        return el.imageV2[type] != null && el.imageV2[type] != "";
                    })
                    .map(product => {
                        return {
                            inventoryItemID: product.inventoryItemID,
                            imageTypeKey: type
                        };
                    });
            });
        },
        imageIndex() {
            return this.allImageArray.findIndex(
                el =>
                    el.inventoryItemID == this.$route.params.inventoryItemID &&
          el.imageTypeKey == this.$route.params.imageTypeKey
            );
        },
        valid() {
            return this.productSelections.length;
        },
        associatedProducts() {
            if (this.imageProduct == undefined) return [];
            return this.products.filter(el => {
                return (
                    el.imageV2[this.$route.params.imageTypeKey] == this.imageName ||
          el.inventoryItemImageName == this.imageName
                );
            });
            return [this.imageProduct];
        },
        ...mapGetters(["store", "productCategories", "products", "productsMap"])
    },
    methods: {
        deleteItem() {
            this.deleteConfirmationModal = false;
            let storeWaypointID = this.$route.params.store;
            let airportIdent = this.store.airportIdent;
            let imageTypeID = this.imageInfo.typeID;

            let image = {
                inventoryItemID: this.imageProduct.inventoryItemID,
                inventoryItemSubID: this.imageProduct.inventoryItemSubs[0]
                    .inventoryItemSubID,
                imageName: ""
            };

            let payload = {
                storeWaypointID,
                airportIdent,
                imageTypeID,
                images: [image]
            };

            return this.setImageV2(payload).then(() => {
                this.initialAssociatedProducts = this.productSelections;
                this.leave();
            });
        },
        gotoNextItem() {
            if (!this.hasNextItem) return;
            let nextItem = this.allImageArray[this.imageIndex + 1];
            this.guardRouteAway({
                name: "image-details",
                params: nextItem
            });
        },
        gotoPreviousItem() {
            if (!this.hasPreviousItem) return;
            let previousItem = this.allImageArray[this.imageIndex - 1];
            this.guardRouteAway({
                name: "image-details",
                params: previousItem
            });
        },
        leave() {
            this.guardRouteAway({
                name: this.leaveRoute
            });
        },
        hasNextItem() {
            if (
                this.imageIndex == -1 ||
        this.imageIndex + 1 >= this.allImageArray.length
            )
                return false;
            return true;
        },
        hasPreviousItem() {
            if (this.imageIndex == -1 || this.imageIndex == 0) return false;
            return true;
        },
        guardRouteAway(route) {
            if (!this.dirty) {
                this.$router.push(route);
                return;
            }

            this.pendingRoute = route;
            this.navigationConfirmationModal = true;
        },
        confirmNavigation() {
            this.navigationConfirmationModal = false;
            this.$router.push(this.pendingRoute);
            this.pendingRoute = undefined;
        },
        handleNewSelections(selectedItems) {
            this.productSelectionModal = false;
            this.productSelections = selectedItems
                .filter(el => !el.group)
                .map(el => this.productsMap[el.id]);
        },
        showProductSelection() {
            this.modalItems = clone(this.productSelections);
            this.productSelectionModal = true;
        },
        save() {
            let storeWaypointID = this.$route.params.store;
            let airportIdent = this.store.airportIdent;
            let imageTypeID = this.imageInfo.typeID;

            var images = [];

            let deletedItems = this.initialAssociatedProducts.filter(el => {
                return !this.productSelections.map(el => el.id).includes(el.id);
            });

            images = images.concat(
                deletedItems.map(el => {
                    return {
                        inventoryItemID: el.inventoryItemID,
                        inventoryItemSubID: el.inventoryItemSubs[0].inventoryItemSubID,
                        imageName: ""
                    };
                })
            );

            let addedItems = this.productSelections.filter(el => {
                return !this.initialAssociatedProducts.map(el => el.id).includes(el.id);
            });

            images = images.concat(
                addedItems.map(el => {
                    return {
                        inventoryItemID: el.inventoryItemID,
                        inventoryItemSubID: el.inventoryItemSubs[0].inventoryItemSubID,
                        imageName: this.imageName
                    };
                })
            );

            let payload = {
                storeWaypointID,
                airportIdent,
                imageTypeID,
                images
            };
            return this.setImageV2(payload).then(() => {
                this.initialAssociatedProducts = this.productSelections;
                this.navigationConfirmationModal = false;
                return Promise.resolve(true);
            });
        },
        init() {
            this.initialAssociatedProducts = clone(this.associatedProducts);
            this.productSelections = clone(this.associatedProducts);
        },
        removeAssociatedItem(item) {
            let index = this.productSelections.findIndex(el => el.id == item.id);
            this.productSelections.splice(index, 1);
        },
        ...mapActions("ImageUploadStore", ["setImageV2"])
    },
    watch: {
        associatedProducts: function(val) {
            this.init();
        }
    }
};
</script>

<style lang="scss" scoped>
@import "~scss/variables";
@import "~scss/mixins";

.row {
  &__title {
    padding-left: spacing(xs);
  }

  &__icon {
    @include background-image("", "image_logo.svg", "../../img/");
    background-size: contain;
    width: spacing(sm);
    height: spacing(sm);
    margin-left: 13px;
  }
}

.delete-logo {
  @include background-image("", "delete.svg", "../../img/");
  background-size: 25px;
  cursor: pointer;
  flex-grow: 0 !important;
  margin-left: 10px;
  padding: 10px;
  width: 25px;
  height: 25px;
  margin-top: -2px;
}

.v-card {
  &__title {
    color: #585858 !important;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 700;
  }

  &__text {
    color: #585858 !important;
    font-size: 14px;
    overflow: hidden;
  }
}
.list-card {
  .tile {
    border-bottom: 1px solid #e4e4e4;
  }

  .header {
    .v-list__tile {
      color: purple;
    }
  }
  .flex.md5 {
    max-width: 25% !important;
  }
}
.v-list {
  margin-right: 5px;
  padding-bottom: 0px !important;
  &__tile {
    border-bottom: 1px solid #e4e4e4;

    &__title {
      color: #585858 !important;
      font-size: 14px;
    }

    &__action {
      min-width: 35px;
      margin-left: 5px;
      .icon {
        cursor: grab;
      }
    }
  }
}
</style>
