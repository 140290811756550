// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../img/image_logo.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../img/delete.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".row__title[data-v-3e7041f6] {\n  padding-left: 12px;\n}\n.row__icon[data-v-3e7041f6] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-repeat: no-repeat;\n  background-size: contain;\n  width: 18px;\n  height: 18px;\n  margin-left: 13px;\n}\n.delete-logo[data-v-3e7041f6] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n  background-repeat: no-repeat;\n  background-size: 25px;\n  cursor: pointer;\n  flex-grow: 0 !important;\n  margin-left: 10px;\n  padding: 10px;\n  width: 25px;\n  height: 25px;\n  margin-top: -2px;\n}\n.v-card__title[data-v-3e7041f6] {\n  color: #585858 !important;\n  font-family: \"Roboto\", sans-serif;\n  font-style: normal;\n  font-weight: 700;\n}\n.v-card__text[data-v-3e7041f6] {\n  color: #585858 !important;\n  font-size: 14px;\n  overflow: hidden;\n}\n.list-card .tile[data-v-3e7041f6] {\n  border-bottom: 1px solid #e4e4e4;\n}\n.list-card .header .v-list__tile[data-v-3e7041f6] {\n  color: purple;\n}\n.list-card .flex.md5[data-v-3e7041f6] {\n  max-width: 25% !important;\n}\n.v-list[data-v-3e7041f6] {\n  margin-right: 5px;\n  padding-bottom: 0px !important;\n}\n.v-list__tile[data-v-3e7041f6] {\n  border-bottom: 1px solid #e4e4e4;\n}\n.v-list__tile__title[data-v-3e7041f6] {\n  color: #585858 !important;\n  font-size: 14px;\n}\n.v-list__tile__action[data-v-3e7041f6] {\n  min-width: 35px;\n  margin-left: 5px;\n}\n.v-list__tile__action .icon[data-v-3e7041f6] {\n  cursor: grab;\n}", ""]);
// Exports
module.exports = exports;
